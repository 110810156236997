import React, { useRef, useEffect, useCallback, useState, useMemo } from 'react';
import ReactQuill from 'react-quill-new';
import 'react-quill-new/dist/quill.snow.css';
import "quill-mention/autoregister";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import Select from "react-select";
import Creatable from "react-select/creatable";
import _ from 'lodash';
import PropTypes from 'prop-types';

const QueryBuilder = React.memo((props) => {
  const id = props.id;
  const clearButtonStyle = props.clearButtonStyle;

  const commandKeys = {
    verbs: 'verbs',
    hierarchies: 'hierarchies',
    measures: 'measures',
    aggregate: 'aggregate',
    limit: 'limit',
    levels: 'levels',
    values: 'values',
    calendar: 'calendar',
    limitCount: 'limitCount',
    limitDirection: 'limitDirection',
    includeExclude: 'includeExclude',
    selectedDateType: 'selectedDateType',
    dateGranularity: 'dateGranularity',
    selectedDate: 'selectedDate',
    selectedDate1: 'selectedDate1',
    dateFormat: 'dateFormat',
    chooseDate: 'chooseDate',
    chooseMonth: 'chooseMonth',
    chooseYear: 'chooseYear'
  };

  const displayKeys = {
    verbs: 'verb',
    hierarchies: 'table',
    measures: 'measure',
    aggregate: 'aggregate',
    limit: '(limit)',
    levels: '(column)',
    values: '(values)',
    calendar: '(calendar)',
    limitCount: 'count',
    limitDirection: 'direction',
    includeExclude: '(include/exclude)',
    selectedDateType: '(date type)',
    dateGranularity: '(date granularity)',
    dateFormat: '(select)',
    selectedDate: 'selected date',
    selectedDate1: 'selected date1',
    chooseDate: '(date)',
    chooseMonth: '(month)',
    chooseYear: '(year)',
  };

  let commandKeysMapping = {};

  commandKeysMapping[commandKeys.verbs] = {
    list: displayKeys[commandKeys.hierarchies],
    show: displayKeys[commandKeys.measures],
    compare: displayKeys[commandKeys.measures],
    'show the trend of': displayKeys[commandKeys.measures]
  };
  commandKeysMapping[commandKeys.measures] = displayKeys[commandKeys.limit];
  commandKeysMapping[commandKeys.limit] = displayKeys[commandKeys.hierarchies];
  commandKeysMapping[commandKeys.hierarchies] = displayKeys[commandKeys.levels];
  commandKeysMapping[commandKeys.levels] = displayKeys[commandKeys.includeExclude];
  commandKeysMapping[commandKeys.includeExclude] = displayKeys[commandKeys.values];
  commandKeysMapping[commandKeys.values] = displayKeys[commandKeys.selectedDateType];
  commandKeysMapping[commandKeys.selectedDateType] = displayKeys[commandKeys.dateGranularity];
  commandKeysMapping[commandKeys.chooseDate] = displayKeys[commandKeys.chooseDate];
  commandKeysMapping[commandKeys.chooseMonth] = displayKeys[commandKeys.chooseMonth];
  commandKeysMapping[commandKeys.chooseYear] = displayKeys[commandKeys.chooseYear];

  const [commands, setCommands] = useState(props.commands);
  const [editorContent, setEditorContent] = useState('');
  const quillRef = useRef(null);
  const [disabledSearch, setDisabledSearch] = useState(true);
  const [commandKey, setCommandKey] = useState("");
  const defaultDateFormat = "MMM DD, YYYY";
  const monthYearFormat = "MMM YYYY";
  const yearFormat = "YYYY";
  const dateFormatRef = useRef(null);
  const endDateRef = useRef(null);
  const [dateFormat, setDateFormat] = useState(defaultDateFormat);
  const [date, setDate] = useState(new Date());
  const [openDate, setOpenDate] = useState(true);
  const [endDate, setEndDate] = useState(new Date());
  const [dateRange, setDateRange] = useState(false);
  const [openEndDate, setOpenEndDate] = useState(false);
  const [showMonthYearPicker, setShowMonthYearPicker] = useState(false);
  const [showYearPicker, setShowYearPicker] = useState(false);
  const [selectedHierarchy, setSelectedHierarchy] = useState("");
  const [selectedLevel, setSelectedLevel] = useState("");
  const [selectedLevelVal, setSelectedLevelVal] = useState("");
  const [limitByCount, setLimitByCount] = useState(false);
  const [limitNumber, setLimitNumber] = useState("");
  const [limitByCountDirection, setLimitByCountDirection] = useState("top");
  const [sugesstionsKeyVal, setSugesstionsKeyVal] = useState({});
  const [selectedAggregate, setSelectedAggregate] = useState(null);
  const [dropdownPosition, setDropdownPosition] = useState({ top: 0, left: 0 });
  const commandsRef = useRef(props.commands);
  const hierarchyIndexRef = useRef(null);

  useEffect(() => {
    commandsRef.current = props.commands;
  }, [props.commands]);

  const mentionDenotationChars = useMemo(() => [
    displayKeys[commandKeys.verbs],
    displayKeys[commandKeys.measures],
    displayKeys[commandKeys.aggregate],
    displayKeys[commandKeys.limit],
    displayKeys[commandKeys.limitCount],
    displayKeys[commandKeys.limitDirection],
    displayKeys[commandKeys.hierarchies],
    displayKeys[commandKeys.levels],
    displayKeys[commandKeys.includeExclude],
    displayKeys[commandKeys.values],
    displayKeys[commandKeys.selectedDateType],
    displayKeys[commandKeys.dateGranularity],
    displayKeys[commandKeys.selectedDate],
    displayKeys[commandKeys.selectedDate1],
    displayKeys[commandKeys.dateFormat],
    displayKeys[commandKeys.chooseDate],
    displayKeys[commandKeys.chooseMonth],
    displayKeys[commandKeys.chooseYear]
  ], []);

  const mentionSourceRef = useRef(() => {});
  useEffect(() => {
    mentionSourceRef.current = mentionSource;
  }, [mentionSource]);

  useEffect(() => {
    resetDisplayControls();
    const quill = getQuillInstance();
    quill.setContents(null);
    quill.blur();
  }, []);

  useEffect(() => {
    if (quillRef.current) {
      const quill = quillRef.current.getEditor();
      quill.on('selection-change', (range) => {
        if (range) {
          const bounds = quill.getBounds(range.index);
          setDropdownPosition({
            top: bounds.top + bounds.height + 8,
            left: bounds.left
          });
        }
      });
    }
  }, []);

  function capitalizeString(str) {
    return str.toLowerCase().replace(/\b\w/g, char => char.toUpperCase());
  }

  function getSelectedMenstionInfo(selectedMention) {
    const quill = getQuillInstance();
    const delta = quill.getContents();
    if (delta?.ops) {
      return delta.ops.find(x => x.insert?.mention?.commandKey === selectedMention)?.insert?.mention;
    }
    return null;
  }

  function getHierarchies(commandsData) {
    const selectedMeasureInfo = getSelectedMenstionInfo(commandKeys.measures);
    let allowedHierarchies = [];
    let hierarchieArr = [];
    if (selectedMeasureInfo?.commandVal && commandsData.measures?.[selectedMeasureInfo.commandVal]) {
      allowedHierarchies = commandsData.measures[selectedMeasureInfo.commandVal].hierarchies;
    }
    if (commandsData.hierarchies && Object.keys(commandsData.hierarchies).length) {
      hierarchieArr = allowedHierarchies.length
        ? Object.keys(commandsData.hierarchies).filter(x => allowedHierarchies.includes(x))
        : Object.keys(commandsData.hierarchies);
    }
    return hierarchieArr.map(commandVal => ({
      value: commandVal.toString().toLowerCase(),
      nextKey: commandKeysMapping.hierarchies,
      commandKey: commandKeys.hierarchies,
      commandVal
    }));
  }

  function getLevels() {
    var selectedHierarchieInfo = getSelectedMenstionInfo(commandKeys.hierarchies);
    if (selectedHierarchieInfo && selectedHierarchieInfo.commandVal && props.commands[commandKeys.hierarchies][selectedHierarchieInfo.commandVal]) {
      if (
        props.commands[commandKeys.hierarchies][selectedHierarchieInfo.commandVal] &&
        props.commands[commandKeys.hierarchies][selectedHierarchieInfo.commandVal][commandKeys.levels] &&
        props.commands[commandKeys.hierarchies][selectedHierarchieInfo.commandVal][commandKeys.levels].length
      ) {
        return props.commands[commandKeys.hierarchies][selectedHierarchieInfo.commandVal].levels.map((commandVal) => {
          return {
            value: commandVal.toString().toLowerCase(),
            nextKey: commandKeysMapping[commandKeys.levels],
            commandKey: commandKeys.levels,
            commandVal: commandVal,
            label: capitalizeString(commandVal)
          };
        });
      }
    } else {
      return [];
    }
  }

  function getValues() {
    var selectedLevelInfo = getSelectedMenstionInfo(commandKeys.levels);
    if (
      selectedHierarchy &&
      props.commands[commandKeys.hierarchies][selectedHierarchy]
    ) {
      if (
        props.commands[commandKeys.hierarchies][selectedHierarchy] &&
        props.commands[commandKeys.hierarchies][selectedHierarchy][commandKeys.values] &&
        Object.keys(props.commands[commandKeys.hierarchies][selectedHierarchy][commandKeys.values]).length
      ) {
        if (
          selectedLevelInfo &&
          props.commands[commandKeys.hierarchies][selectedHierarchy].values[selectedLevelInfo.commandVal] &&
          props.commands[commandKeys.hierarchies][selectedHierarchy].values[selectedLevelInfo.commandVal].length
        ) {
          return props.commands[commandKeys.hierarchies][selectedHierarchy].values[selectedLevelInfo.commandVal].map((commandVal) => {
            return {
              value: commandVal.toString().toLowerCase(),
              nextKey: commandKeysMapping[commandKeys.values],
              commandKey: commandKeys.values,
              commandVal: commandVal,
              label: commandVal.toString(),
            };
          });
        } else {
          return [];
        }
      }
    } else {
      return [];
    }
  }

  function getIncludedExclude() {
    let options = [
      {
        value: "Include",
        nextKey: commandKeysMapping[commandKeys.includeExclude],
        commandKey: commandKeys.includeExclude,
        commandVal: "include"
      },
      {
        value: "Exclude",
        nextKey: commandKeysMapping[commandKeys.includeExclude],
        commandKey: commandKeys.includeExclude,
        commandVal: "exclude"
      }
    ];

    const verbMention = getSelectedMenstionInfo(commandKeys.verbs);
    if (!verbMention || verbMention.commandVal.toLowerCase() !== 'list') {
      options.push({
        value: "None",
        nextKey: displayKeys[commandKeys.selectedDateType],
        commandKey: commandKeys.includeExclude,
        commandVal: "none"
      });
    }

    return options;
  }

  function getDateTypes() {
    return [
      {
        value: "on",
        nextKey: commandKeysMapping[commandKeys.selectedDateType],
        commandKey: commandKeys.selectedDateType,
        commandVal: 'On'
      },
      {
        value: "before",
        nextKey: commandKeysMapping[commandKeys.selectedDateType],
        commandKey: commandKeys.selectedDateType,
        commandVal: 'Before'
      },
      {
        value: "after",
        nextKey: commandKeysMapping[commandKeys.selectedDateType],
        commandKey: commandKeys.selectedDateType,
        commandVal: 'After'
      },
      {
        value: "between",
        nextKey: commandKeysMapping[commandKeys.selectedDateType],
        commandKey: commandKeys.selectedDateType,
        commandVal: 'Between'
      },
      {
        value: "none",
        nextKey: "",
        commandKey: commandKeys.selectedDateType,
        commandVal: 'None'
      }
    ];
  }

  function getDateGranularity() {
    return [
      {
        value: "Date",
        nextKey: commandKeysMapping[commandKeys.chooseDate],
        commandKey: commandKeys.selectedDate,
        commandVal: 'date'
      },
      {
        value: "Month",
        nextKey: commandKeysMapping[commandKeys.chooseMonth],
        commandKey: commandKeys.selectedDate,
        commandVal: 'month'
      },
      {
        value: "Year",
        nextKey: commandKeysMapping[commandKeys.chooseYear],
        commandKey: commandKeys.selectedDate,
        commandVal: 'year'
      },
    ];
  }

  function getAggregateOptions() {
    const selectedMeasureInfo = getSelectedMenstionInfo(commandKeys.measures);
    if (selectedMeasureInfo && selectedMeasureInfo.commandVal) {
      const measureKey = selectedMeasureInfo.commandVal;
      const measures = commandsRef.current.measures || {};
      const measureInfo = measures[measureKey];
      if (measureInfo && measureInfo.agg && measureInfo.agg.length) {
        const options = measureInfo.agg;
        const verbMention = getSelectedMenstionInfo(commandKeys.verbs);
        const nextKey = verbMention?.commandVal.toLowerCase() ===  'show the trend of'
            ? displayKeys[commandKeys.hierarchies]
            : displayKeys[commandKeys.limit];

        return options.map((agg) => {
          return {
            value: agg,
            nextKey: nextKey,
            commandKey: commandKeys.aggregate,
            commandVal: agg,
            label: capitalizeString(agg)
          };
        });
      }
    }
    return [];
  }

  function getDefaultSuggestions(mentionChar) {
    const currentCommands = commandsRef.current;
    if (!currentCommands || !Object.keys(currentCommands).length) return [];

    const foundCommandKey = Object.keys(commandKeys).find(
      (ck) => displayKeys[commandKeys[ck]] === mentionChar
    );
    if (!foundCommandKey) return [];

    const dataForKey = currentCommands[foundCommandKey];
    if (!dataForKey) return [];

    const cmdKey = foundCommandKey;
    let suggestions = [];

    if (Array.isArray(dataForKey) && dataForKey.length) {
      suggestions = dataForKey.map((commandVal) => {
        if (cmdKey === commandKeys.verbs) {
          return {
            value: commandVal,
            nextKey: commandKeysMapping[cmdKey][commandVal.toString().toLowerCase()],
            commandKey: cmdKey,
            commandVal
          };
        }
        if (cmdKey === commandKeys.measures) {
          const measureInfo =currentCommands.measures[commandVal];
          const verbMention = getSelectedMenstionInfo(commandKeys.verbs);
          let nextKey;
          if (measureInfo?.agg?.length > 0) {
            nextKey = displayKeys[commandKeys.aggregate];
          } else if (verbMention?.commandVal.toLowerCase() === 'show the trend of') {
            nextKey = displayKeys[commandKeys.hierarchies];
          } else {
            nextKey = displayKeys[commandKeys.limit];
          }
          return {
            value: commandVal.toString().toLowerCase(),
            nextKey,
            commandKey: cmdKey,
            commandVal
          };
        }
        return {
          value: commandVal.toString().toLowerCase(),
          nextKey: commandKeysMapping[cmdKey],
          commandKey: cmdKey,
          commandVal
        };
      });
      return suggestions;
    }

    if (typeof dataForKey === 'object' && dataForKey !== null) {
      suggestions = Object.keys(dataForKey).map((commandVal) => {
        if (cmdKey === commandKeys.verbs) {
          return {
            value: commandVal.toString().toLowerCase(),
            nextKey: commandKeysMapping[cmdKey][commandVal],
            commandKey: cmdKey,
            commandVal
          };
        }
        if (cmdKey === commandKeys.measures) {
          const measureInfo =currentCommands.measures[commandVal];
          const verbMention = getSelectedMenstionInfo(commandKeys.verbs);
          let nextKey;
          if (measureInfo?.agg?.length > 0) {
            nextKey = displayKeys[commandKeys.aggregate];
          } else if (verbMention?.commandVal.toLowerCase() === 'show the trend of') {
            nextKey = displayKeys[commandKeys.hierarchies];
          } else {
            nextKey = displayKeys[commandKeys.limit];
          }
          return {
            value: commandVal.toString().toLowerCase(),
            nextKey,
            commandKey: cmdKey,
            commandVal
          };
        }
        return {
          value: commandVal.toString().toLowerCase(),
          nextKey: commandKeysMapping[cmdKey],
          commandKey: cmdKey,
          commandVal
        };
      });
      return suggestions;
    }

    return [];
  }

  const mentionSource = useCallback((searchTerm, renderList, mentionChar) => {
    let values = [];
    const currentCommands = commandsRef.current;
    switch (mentionChar) {
      case displayKeys.hierarchies:
        values = getHierarchies(currentCommands);
        break;
      case displayKeys[commandKeys.aggregate]:
        values = getAggregateOptions();
        break;
      case displayKeys.includeExclude:
        values = getIncludedExclude();
        break;
      case displayKeys.selectedDateType:
        values = getDateTypes();
        break;
      case displayKeys.dateGranularity:
        values = getDateGranularity();
        break;
      default:
        values = getDefaultSuggestions(mentionChar);
    }
    const filtered = values.filter(v => v.value.toLowerCase().includes(searchTerm.toLowerCase()));
    renderList(filtered, searchTerm);
  }, [commandsRef.current]);

  const modules = useMemo(() => {
    if (mentionDenotationChars.length) {
      return {
        toolbar: false,
        mention: {
          dataAttributes: ['value', 'nextKey', 'commandKey', 'commandVal'],
          mentionDenotationChars: mentionDenotationChars,
          source: (searchTerm, renderList, mentionChar) => {
            mentionSourceRef.current(searchTerm, renderList, mentionChar);
          },
          onSelect: function (item, insertItem) {
            const currentCommands = commandsRef.current;
            const quill = quillRef.current.getEditor();
            let cursorPosition = quill.getSelection()?.index;
            cursorPosition = cursorPosition - item.denotationChar.length;
            quill.deleteText(cursorPosition, item.denotationChar.length);
            let nextKey = item.nextKey ? item.nextKey : null;
            let displayValue = nextKey ? item.value : "";
            let includeDisplayValue = true;
            if (item.value) {
              if (item.commandKey === commandKeys.selectedDateType && item.commandVal !== "Between") {
                setDateRange(false);
              }
              if (item.commandVal === "Between") {
                setDateRange(true);
              } else if (item.commandVal === "date") {
                setDateFormat(defaultDateFormat);
                dateFormatRef.current = defaultDateFormat
                setShowMonthYearPicker(false);
                setShowYearPicker(false);
                displayValue = "";
              } else if (item.commandVal === "month" || item.commandVal === "year" ) {
                includeDisplayValue = false;
              } else if (item.commandKey === commandKeys.includeExclude) {
                if (item.commandVal === "none") {
                  displayValue = "";
                } else {
                  displayValue = item.commandVal === "include" ? "" : "not in";
                }
              } else if (item.commandKey === commandKeys.hierarchies) {
                  hierarchyIndexRef.current = cursorPosition;
                  const verbMention = getSelectedMenstionInfo(commandKeys.verbs);
                  if (verbMention && verbMention.commandVal.toLowerCase() === 'list') {
                    displayValue = `${item.value} details`;
                  } else {
                    displayValue = `${item.value}`;
                  }
                  setSelectedHierarchy(item.commandVal);
              } else if (item.commandKey === commandKeys.measures) {
                displayValue = `${item.value} for`;
              } else if (item.commandKey === commandKeys.aggregate) {
                includeDisplayValue = false;
              }
            }
            if (displayValue) {
              const mentionData = { ...item, denotationChar: "", value: includeDisplayValue ? displayValue : "" };
              quill.insertEmbed(cursorPosition, 'mention', mentionData);
            }
            const length = quill.getLength();
            quill.setSelection(length, 0);
            setCommandKey(nextKey);
            if (nextKey) {
              if (
                nextKey === displayKeys[commandKeys.chooseDate] ||
                nextKey === displayKeys[commandKeys.chooseMonth] ||
                nextKey === displayKeys[commandKeys.chooseYear]
              ) {
                if (nextKey === displayKeys[commandKeys.chooseMonth]) {
                  dateFormatRef.current = monthYearFormat
                  setDateFormat(monthYearFormat);
                  setShowMonthYearPicker(true);
                  setShowYearPicker(false);
                } else if (nextKey === displayKeys[commandKeys.chooseYear]) {
                  dateFormatRef.current = yearFormat
                  setDateFormat(yearFormat);
                  setShowYearPicker(true);
                  setShowMonthYearPicker(false);
                }
              }
            } else {
              setDisabledSearch(false);
            }
          },
        },
      };
    }
  }, [mentionDenotationChars]);

  const handleDateChange = useCallback((date) => {
    setDate(date);
    setOpenDate(false);
    if (dateRange) {
      setEndDate(date);
      setTimeout(() => {
        endDateRef.current?.input.focus();
        setOpenEndDate(true);
      }, 0.1);
    } else {
      setDataOnDateChange(true, date, null);
    }
  }, [dateRange]);

  const handleEndDateChange = useCallback((endDt) => {
    setEndDate(endDt);
    setOpenDate(false);
    setOpenEndDate(false);
    setDataOnDateChange(true, date, endDt);
  }, [date, dateRange]);

  function getISOFormat(currentFormat) {
    if (currentFormat === "MMM DD, YYYY") {
      return 'YYYY-MM-DD';
    } else if (currentFormat === "MMM YYYY") {
      return 'YYYY-MM';
    } else if (currentFormat === "YYYY") {
      return 'YYYY';
    }
    return 'YYYY-MM-DD';
  }

  const setDataOnDateChange = useCallback((hideDatepickers, date1, date2) => {
    const quill = quillRef.current.getEditor();
    quill.focus();
    let cursorPosition = quill.getSelection()?.index;
    let mentionData = {
      denotationChar: "",
      value: '',
      commandKey: commandKeys.dateFormat,
      commandVal: dateFormat
    };
    let currentDateFormat = dateFormatRef.current
    quill.insertEmbed(cursorPosition, 'mention', mentionData);
    mentionData = {
      denotationChar: "",
      value: moment(date1).format(currentDateFormat),
      commandKey: commandKeys.selectedDate,
      commandVal: moment(date1).format(getISOFormat(currentDateFormat))
    };
    quill.insertEmbed(cursorPosition, 'mention', mentionData);
    if (dateRange) {
      cursorPosition = cursorPosition + 1;
      mentionData = {
        denotationChar: "",
        value: `and`,
        commandKey: '',
        commandVal: '',
      };
      quill.insertEmbed(cursorPosition, 'mention', mentionData);
      cursorPosition = cursorPosition + 1;
      mentionData = {
        denotationChar: "",
        value: moment(date2).format(currentDateFormat),
        commandKey: commandKeys.selectedDate1,
        commandVal: moment(date2).format(getISOFormat(currentDateFormat))
      };
      quill.insertEmbed(cursorPosition, 'mention', mentionData);
    }
    const length = quill.getLength();
    quill.setSelection(length, 0);
    if (hideDatepickers) {
      setCommandKey("");
      setDisabledSearch(false);
    }
  }, [date, dateFormat, dateRange]);

  const showMenu = useCallback(() => {
    if (quillRef && quillRef.current) {
      const quill = quillRef.current.getEditor();
      setCommandKey("verb");
      quill.getModule("mention").openMenu(displayKeys[commandKeys.verbs]);
    }
  }, []);

  const handleFocus = useCallback((e) => {
    if (e && e.index === 0) {
      showMenu();
    }
  }, [showMenu]);

  function setUsedMentionKeysOnBackspace() {
    const quill = quillRef.current.getEditor();
    var quillContents = quill.getContents();
    if (quillContents && quillContents.ops && quillContents.ops.length) {
      if (quillContents.ops[quillContents.ops.length - 1] && quillContents.ops[quillContents.ops.length - 1].insert && quillContents.ops[quillContents.ops.length - 1].insert.mention) {
        setCommandKey(quillContents.ops[quillContents.ops.length - 1].insert.mention.nextKey);
        setDisabledSearch(true);
      } else if (quillContents.ops[quillContents.ops.length - 2] && quillContents.ops[quillContents.ops.length - 2].insert && quillContents.ops[quillContents.ops.length - 2].insert.mention) {
        setCommandKey(quillContents.ops[quillContents.ops.length - 2].insert.mention.nextKey);
        setDisabledSearch(true);
      }
      setTimeout(() => {
        quill.focus();
        const length = quill.getLength();
        quill.setSelection(length, 0);
      }, 100);
    }
  }

  function resetDisplayControls() {
    setOpenDate(true);
    setDate(new Date());
    setEndDate(new Date());
    setSelectedLevel('');
    setSelectedLevelVal('');
    setLimitByCount(false);
    setLimitNumber("");
    setLimitByCountDirection("top");
    setDisabledSearch(true);
  }

  function getQuillInstance() {
    const quill = quillRef.current.getEditor();
    return quill;
  }

  const onKeyDownQuill = useCallback((e) => {
    if (e.key === 'Backspace') {
      const quill = quillRef.current.getEditor();
      const selection = quill.getSelection();
      if (selection && selection.index > 0) {
        var quillContents = quill.getContents();
        if (quillContents.ops && quillContents.ops.length) {
          let lastOps = quillContents.ops[quillContents.ops.length - 1];
          if (lastOps.insert && lastOps.insert.mention) {
          } else if (lastOps.insert) {
            var deletedString = lastOps.insert.replace("\n", "");
            if (deletedString) {
              let cursorPosition = selection.index - deletedString.length;
              quill.deleteText(cursorPosition, deletedString.length);
            }
          }
          quill.blur();
          setTimeout(() => {
            const length = quill.getLength();
            quill.setSelection(length, 0);
            setUsedMentionKeysOnBackspace();
          }, 0.1);
        }
      } else {
        resetDisplayControls();
        showMenu();
      }
    } else {
        // Keep the read-onlyish behavior for letters
        resetDisplayControls();
        e.preventDefault();
        return false;
      }
    }, [mentionDenotationChars]);

  const skipCommand = useCallback((currentCommand, nextCommand) => {
    const quill = getQuillInstance();
    quill.focus();
    let cursorPosition = quill.getSelection()?.index;
    cursorPosition = cursorPosition - currentCommand.length;
    quill.deleteText(cursorPosition, currentCommand.length);
    setCommandKey(nextCommand);
    setTimeout(() => {
      quill.focus();
      const length = quill.getLength();
      quill.setSelection(length, 0);
    }, 100);
  }, []);

  const onLimitByCountNext = useCallback(() => {
    const quill = quillRef.current.getEditor();
    quill.focus();
    let cursorPosition = quill.getSelection()?.index;
    cursorPosition = cursorPosition - commandKey.length;
    quill.deleteText(cursorPosition, commandKey.length);
    if (limitByCount && limitNumber) {
      let mentionData = {
        denotationChar: "",
        value: `${limitByCountDirection}`,
        commandKey: commandKeys.limitDirection,
        commandVal: limitByCountDirection
      };
      quill.insertEmbed(cursorPosition, 'mention', mentionData);
      cursorPosition = cursorPosition + 1;
      mentionData = {
        denotationChar: "",
        value: `${limitNumber} `,
        commandKey: commandKeys.limitCount,
        commandVal: limitNumber
      };
      quill.insertEmbed(cursorPosition, 'mention', mentionData);
    }
    const length = quill.getLength();
    quill.setSelection(length, 0);
    setCommandKey(displayKeys[commandKeys.hierarchies]);
  }, [limitByCount, limitByCountDirection, limitNumber, commandKey]);

  const onLevelValueChanged = useCallback((e) => {
    const withValueOnly = e ? e.filter(x => x.value !== '') : [];
    setSelectedLevelVal(withValueOnly);
  }, [commandKey]);

  // In onSelectLevelVal, update measure embed (for non‑list flows) if a value is selected.
  const onSelectLevelVal = useCallback((e) => {
    const quill = quillRef.current.getEditor();
    quill.focus();
    let cursorPosition = quill.getSelection()?.index;
    cursorPosition = cursorPosition - commandKey.length;
    quill.deleteText(cursorPosition, commandKey.length);
    if (selectedLevelVal && selectedLevelVal.length) {
      const count = selectedLevelVal.length;
      selectedLevelVal.forEach((currentVal, indx) => {
        if (indx === 0) {
          let mentionData = {
            denotationChar: "",
            value: currentVal.value,
            commandKey: commandKeys.values,
            commandVal: currentVal.value,
            nextKey: displayKeys[commandKeys.values]
          };
          quill.insertEmbed(cursorPosition, 'mention', mentionData);
          cursorPosition++;
        } else {
          let prefix =
            count === 2
              ? "and"
              : (indx === count - 1 ? ", and" : ",");
          let prefixMention = {
            denotationChar: "",
            value: prefix,
            commandKey: "",
            commandVal: "",
            nextKey: displayKeys[commandKeys.values]
          };
          quill.insertEmbed(cursorPosition, 'mention', prefixMention);
          cursorPosition++;
          let mentionData = {
            denotationChar: "",
            value: currentVal.value,
            commandKey: commandKeys.values,
            commandVal: currentVal.value,
            nextKey: displayKeys[commandKeys.values]
          };
          quill.insertEmbed(cursorPosition, 'mention', mentionData);
          cursorPosition++;
        }
        const length = quill.getLength();
        quill.setSelection(length, 0);
      });
      setCommandKey(commandKeysMapping[commandKeys.values]);
    } else {
      setCommandKey(commandKeysMapping[commandKeys.values]);
    }
  }, [selectedLevelVal, commandKey]);  

  const onLevelChanged = useCallback((e) => {
    if (e) {
      setSelectedLevel(e);
    } else {
      setSelectedLevel(null);
    }
  }, [commandKey]);

  const onSelectLevel = useCallback((e) => {
    const quill = quillRef.current.getEditor();
    quill.focus();

    const verbMention = getSelectedMenstionInfo(commandKeys.verbs);
    if (verbMention && verbMention.commandVal.toLowerCase() !== 'list') {
      let index = hierarchyIndexRef.current;
      if (typeof index === 'number') {
        quill.deleteText(index, 1);
      }
    }
    let cursorPosition = quill.getSelection()?.index;
    cursorPosition = cursorPosition - commandKey.length;
    quill.deleteText(cursorPosition, commandKey.length);

    if (selectedLevel) {
      let prefix = "";
      if (verbMention.commandVal.toLowerCase() === 'list') {
        prefix = "for ";
      }
      let mentionData = {
        denotationChar: "",
        value: `${prefix}${selectedLevel.value}`,
        commandKey: selectedLevel.commandKey,
        commandVal: selectedLevel.commandVal,
      };
      quill.insertEmbed(cursorPosition, 'mention', mentionData);
      cursorPosition = cursorPosition + 1;

      const length = quill.getLength();
      quill.setSelection(length, 0);
      setCommandKey(commandKeysMapping[commandKeys.levels]);
    } else {
      setCommandKey(displayKeys[commandKeys.selectedDateType]);
    }
  }, [selectedLevel, commandKey]);

  const prepareRequest = () => {
    const quill = quillRef.current.getEditor();
    const delta = quill.getContents();
    const htmlContent = quill.root.innerHTML;
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = htmlContent;
    const plainText = tempDiv.textContent || tempDiv.innerText;
    let rqData = {
      "question": plainText.replace(/<[^>]+>/g, '').replace(/\s+/g, ' ').trim(),
      "is_query_builder": true,
      "query_builder": {
        "verb": "",
        "measure": "",
        "agg": "",
        "hierarchy": "",
        "level": "",
        calendar: {},
        counts: ""
      }
    };
    let dyRqParam = {};
    if (delta && delta.ops && delta.ops.length) {
      delta.ops.map((v) => {
        if (v.insert && v.insert.mention && v.insert.mention.commandKey && v.insert.mention.commandVal) {
          if (v.insert.mention.commandKey === commandKeys.values) {
            if (dyRqParam[v.insert.mention.commandKey] && dyRqParam[v.insert.mention.commandKey].length) {
              dyRqParam[v.insert.mention.commandKey].push(v.insert.mention.commandVal);
            } else {
              dyRqParam[v.insert.mention.commandKey] = [v.insert.mention.commandVal];
            }
          } else {
            dyRqParam[v.insert.mention.commandKey] = v.insert.mention.commandVal;
          }
        }
      });
    }
    if (dyRqParam.measures) {
      rqData.query_builder.measure = dyRqParam.measures;
      if (dyRqParam.aggregate && dyRqParam.aggregate !== "none") {
        rqData.query_builder.agg = dyRqParam.aggregate;
      }
    }
    rqData.query_builder.verb = dyRqParam.verbs;
    rqData.query_builder.hierarchy = selectedHierarchy ? selectedHierarchy : "";
    rqData.query_builder.level = dyRqParam.levels ? dyRqParam.levels : "";
    if (dyRqParam.limitDirection || dyRqParam.limitCount) {
      let limitDirection = dyRqParam.limitDirection || "top";
      let limitCount = dyRqParam.limitCount || "1";
      rqData.query_builder.counts = limitDirection + " " + limitCount;
    }
    let includeExclude = dyRqParam.includeExclude || "include";
    let selectedDate = dyRqParam.selectedDate;
    let selectedDate1 = dyRqParam.selectedDate1;
    if (dyRqParam.values) {
      var rqValue = {
        [includeExclude]: dyRqParam.values
      };
      rqData.query_builder.value = rqValue;
    }
    if (dyRqParam.selectedDateType) {
      if (dyRqParam.selectedDateType !== "None") {
        const dateColumn = "Date";
        var rqCalendar = {};
        if (dyRqParam.selectedDateType === "Between") {
          rqCalendar[dateColumn] = [
            "%%",
            selectedDate,
            selectedDate1,
          ];
        } else if (dyRqParam.selectedDateType === "On") {
          rqCalendar[dateColumn] = ["=", selectedDate];
        } else if (dyRqParam.selectedDateType === "Before") {
          rqCalendar[dateColumn] = ["<", selectedDate];
        } else if (dyRqParam.selectedDateType === "After") {
          rqCalendar[dateColumn] = [">", selectedDate];
        }
        rqData.query_builder.calendar = rqCalendar;
      }
    }
    console.log(rqData)
    return rqData;
  };

  useEffect(() => {
    if (commandKey) {
      const quill = getQuillInstance();
      resetDisplayControls();
      if (commandKey === displayKeys[commandKeys.measures]) {
        quill.getModule("mention").openMenu(displayKeys[commandKeys.measures]);
      }
      if (commandKey === displayKeys[commandKeys.aggregate]) {
        quill.getModule("mention").openMenu(displayKeys[commandKeys.aggregate]);
      }
      else if (commandKey === displayKeys[commandKeys.limit]) {
        quill.getModule("mention").openMenu(displayKeys[commandKeys.limit]);
      }
      else if (commandKey === displayKeys[commandKeys.hierarchies]) {
        quill.getModule("mention").openMenu(displayKeys[commandKeys.hierarchies]);
      }
      else if (commandKey === displayKeys[commandKeys.levels]) {
        quill.getModule("mention").openMenu(displayKeys[commandKeys.levels]);
      }
      else if (commandKey === displayKeys[commandKeys.values]) {
        quill.getModule("mention").openMenu(displayKeys[commandKeys.values]);
      }
      else if (commandKey === displayKeys[commandKeys.includeExclude]) {
        quill.getModule("mention").openMenu(displayKeys[commandKeys.includeExclude]);
      }
      else if (commandKey === displayKeys[commandKeys.selectedDateType]) {
        quill.getModule("mention").openMenu(displayKeys[commandKeys.selectedDateType]);
      }
      else if (commandKey === displayKeys[commandKeys.dateGranularity]) {
        quill.getModule("mention").openMenu(displayKeys[commandKeys.dateGranularity]);
      }
    }
  }, [commandKey]);

  useEffect(() => {
    if (mentionDenotationChars && mentionDenotationChars.length && quillRef && quillRef.current) {
      const quill = quillRef.current.getEditor();
      quill.on('text-change', () => {
        setEditorContent(quill.getContents());
        const rqData = prepareRequest();
        if (props.setProps && rqData) {
          props.setProps({ quillValue: JSON.stringify(rqData["question"], null, 2) });
          props.setProps({ quillData: rqData });
        }
      });
    }
  }, [mentionDenotationChars, quillRef, selectedHierarchy]);

  useEffect(() => {
    window.addEventListener('mention-hovered', (event) => {}, false);
    window.addEventListener('mention-clicked', (event) => {}, false);
  }, []);

  return (
    <div id={id}>
      <div className='sw-quill-main' style={{ position: 'relative' }}>
        {mentionDenotationChars.length ? (
          <ReactQuill
            ref={quillRef}
            value={editorContent}
            onFocus={handleFocus}
            modules={modules}
            placeholder=""
            onKeyDown={onKeyDownQuill}
          />
        ) : null}
        <button
          className='sw-btn-clear'
          style={{
            position: 'absolute',
            right: '4rem',
            top: '10px',
            backgroundColor: 'transparent',
            border: '2px solid #616161',
            fontSize: '20px',
            cursor: 'pointer',
            width: '24px',
            height: '24px',
            borderRadius: '50%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: 0,
            color: 'black',
            zIndex: 3000,
            ...clearButtonStyle,
          }}
          onClick={() => {
            setCommandKey(null);
            resetDisplayControls();
            const quill = getQuillInstance();
            quill.setContents(null);
            quill.blur();
          }}
        >
          ×
        </button>
      </div>

      <div 
        className="qb-box-container" 
        style={{ 
          position: 'relative',
          left: dropdownPosition.left,
          zIndex: 9999,
          visibility: commandKey ? 'visible' : 'hidden'
        }}
      >
        {(commandKey === displayKeys[commandKeys.chooseDate] ||
          commandKey === displayKeys[commandKeys.chooseMonth] ||
          commandKey === displayKeys[commandKeys.chooseYear]) && (
          <div>
            <div className='sw-datepicker-main sw-outer-sug-ctrl'>
              <div>
                <label className='lbl-dp'>
                  {dateRange ? `Select Start Date` : 'Select Date'}
                </label>
                <DatePicker
                  selected={date}
                  open={openDate}
                  showMonthYearPicker={showMonthYearPicker}
                  showYearPicker={showYearPicker}
                  onChange={handleDateChange}
                  shouldCloseOnSelect={true}
                />
              </div>
              <div className={`${dateRange ? '' : 'd-none'}`}>
                <label className='lbl-dp'>Select End Date</label>
                <DatePicker
                  selected={endDate}
                  onChange={handleEndDateChange}
                  selectsEnd
                  startDate={date}
                  endDate={endDate}
                  minDate={date}
                  showMonthYearPicker={showMonthYearPicker}
                  showYearPicker={showYearPicker}
                  open={openEndDate}
                  shouldCloseOnSelect={true}
                  ref={endDateRef}
                />
              </div>
            </div>
          </div>
        )}

        {commandKey === displayKeys[commandKeys.limit] && (
          <div className='sw-outer-sug-ctrl'>
            <div className='sw-limit-bycnt'>
              <div>
                <label className='sw-label-main'>
                  <input
                    type="checkbox"
                    defaultValue={limitByCount}
                    onChange={(e) => { setLimitByCount(e.target.checked); }}
                  />
                  Limit by count?
                </label>
              </div>
              {limitByCount && (
                <>
                  <div>
                    <label>
                      Number :{' '}
                      <input
                        type="number"
                        min={1}
                        defaultValue={limitNumber}
                        onChange={(e) => { setLimitNumber(e.target.value); }}
                        className='sw-input'
                      />
                    </label>
                  </div>
                  <div className='sw-limitby-count-dir'>
                    <label htmlFor="limitByCountTop">
                      <input
                        type="radio"
                        id='limitByCountTop'
                        name='limitByCount'
                        value="top"
                        defaultChecked
                        onChange={(e) => { setLimitByCountDirection(e.target.value); }}
                      />
                      Top
                    </label>
                    <label htmlFor="limitByCountBottom">
                      <input
                        type="radio"
                        id='limitByCountBottom'
                        name='limitByCount'
                        value="bottom"
                        onChange={(e) => { setLimitByCountDirection(e.target.value); }}
                      />
                      Bottom
                    </label>
                  </div>
                </>
              )}
            </div>
          </div>
        )}

        {commandKey === displayKeys[commandKeys.levels] && (
          <div className='sw-outer-sug-ctrl'>
            <div className='sw-choose-heirarchy'>
              <Creatable
                isClearable={true}
                isSearchable={true}
                className="basic-single"
                classNamePrefix="select"
                value={selectedLevel}
                options={getLevels()}
                onChange={onLevelChanged}
                autoFocus={true}
                placeholder="Select column"
              />
            </div>
          </div>
        )}

        {commandKey === displayKeys[commandKeys.values] && (
          <div className='sw-outer-sug-ctrl'>
            <div className='sw-limit-bycnt'>
                <div>
                <label className='sw-label-main'>Sample Value(s)</label>
                </div>
            </div>
            <div className='sw-choose-heirarchy'>
              <Creatable
                isMulti
                className="basic-single"
                classNamePrefix="select"
                value={selectedLevelVal}
                options={getValues()}
                onChange={onLevelValueChanged}
                autoFocus={true}
                placeholder="Select or type value(s)"
              />
            </div>
          </div>
        )}

        <div className='se-step-footer'>
          {commandKey === displayKeys[commandKeys.limit] && (
            <div>
              <button className='sw-btn-skip' onClick={() => { skipCommand(commandKey, displayKeys[commandKeys.hierarchies]); }}>Skip</button>
              <button className='sw-btn-next' disabled={!limitNumber} onClick={onLimitByCountNext}>Next</button>
            </div>
          )}
          {commandKey === displayKeys[commandKeys.levels] && (
            <div>
              <button className='sw-btn-skip' onClick={() => { skipCommand(commandKey, displayKeys[commandKeys.selectedDateType]); }}>Skip</button>
              <button className='sw-btn-next' disabled={!selectedLevel} onClick={onSelectLevel}>Next</button>
            </div>
          )}
          {commandKey === displayKeys[commandKeys.values] && (
            <div>
              <button className='sw-btn-skip' onClick={() => { skipCommand(commandKey, displayKeys[commandKeys.selectedDateType]); }}>Skip</button>
              <button className='sw-btn-next' disabled={!selectedLevelVal?.length} onClick={onSelectLevelVal}>Next</button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
});

QueryBuilder.propTypes = {
  id: PropTypes.string,
  commands: PropTypes.object.isRequired,
  quillValue: PropTypes.string,
  quillData: PropTypes.object,
  clearButtonStyle: PropTypes.object,
  setProps: PropTypes.func,
};

QueryBuilder.defaultProps = {
  id: '',
  clearButtonStyle: {
    backgroundColor: 'transparent',
    border: 'none',
    fontSize: '16px',
    cursor: 'pointer',
  },
};

export default QueryBuilder;
import PropTypes from 'prop-types';
import Select from 'react-select';
import React, { useState, useEffect, useRef } from 'react';

/**
 * SmartDropdown is an interactive dropdown element for selecting one or more items.
 * It is compatible with Dash `dcc.Dropdown` props.
 */
const SmartDropdown = ({
    id, options, multi, clearable, searchable, disabled, placeholder, value, setProps,
    closeMenuOnSelect, style, collapsible,openIcon, closeIcon, ...props
}) => {
    const SELECT_ALL_OPTION = { value: "SELECT_ALL", label: "Select All" };
    const [menuOpen, setMenuOpen] = useState(false);

    // This ref is used to detect clicks outside.
    const containerRef = useRef(null);

    const normalizeValue = (val) => {
        if (multi) {
            if (Array.isArray(val)) {
                return options.length ? options.filter(option => val.includes(option.value)) : [];
            }
            return [];
        }
        return options.length ? options.find(option => option.value === val) || null : null;
    };

    const [selectedValue, setSelectedValue] = useState(normalizeValue(value));

    useEffect(() => {
        setSelectedValue(normalizeValue(value));
    }, [value, options]);

    const handleChange = (selectedOption) => {
        if (multi) {
            const selectedValues = selectedOption ? selectedOption.map(option => option.value) : [];
            const hasSelectAll = selectedValues.includes(SELECT_ALL_OPTION.value);

            if (hasSelectAll) {
                const actualOptionValues = options.map(opt => opt.value);
                const allSelected = actualOptionValues.every(val => selectedValues.includes(val));
                if (allSelected) {
                    setSelectedValue([]);
                    setProps?.({ value: [] });
                } else {
                    const newSelected = options.filter(opt => !opt.isDisabled);
                    setSelectedValue(newSelected);
                    setProps?.({ value: newSelected.map(opt => opt.value) });
                }
            } else {
                const filteredSelected = selectedOption.filter(opt => opt.value !== SELECT_ALL_OPTION.value);
                setSelectedValue(filteredSelected);
                setProps?.({ value: filteredSelected.map(opt => opt.value) });
            }
        } else {
            setSelectedValue(selectedOption);
            setProps?.({ value: selectedOption?.value });

            if (collapsible && closeMenuOnSelect) {
                setMenuOpen(false);
            }
        }
    };

    // Combine our normal options with Select All (for multi)
    const dropdownOptions = multi ? [SELECT_ALL_OPTION, ...options] : options;

    // Outside click handler
    useEffect(() => {
        // Only listen if menu is open
        if (!collapsible || !menuOpen) return;

        const handleOutsideClick = (e) => {
            if (containerRef.current && !containerRef.current.contains(e.target)) {
                // If the user clicked outside the container, close the menu
                setMenuOpen(false);
            }
        };

        document.addEventListener('mousedown', handleOutsideClick);
        // Cleanup: remove event listener
        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, [menuOpen, collapsible]);

    return (
        <div className="smart-dropdown" ref={containerRef}>
            {collapsible && (
                <label onClick={() => setMenuOpen(!menuOpen)} style={{ cursor: "pointer", textAlign: "center" }}>
                    {menuOpen ? (
                        closeIcon ? <img src={closeIcon} alt="Close" /> : <span>Close</span>
                    ) : (
                        openIcon ? <img src={openIcon} alt="Open" /> : <span>Open</span>
                    )}
                </label>
            )}
            <Select
                id={id}
                options={dropdownOptions}
                isMulti={multi}
                isClearable={clearable}
                isSearchable={searchable}
                isDisabled={disabled}
                placeholder={placeholder}
                value={selectedValue}
                onChange={handleChange}
                closeMenuOnSelect={closeMenuOnSelect}
                menuIsOpen={collapsible ? menuOpen : undefined}
                components={collapsible ? { Control: () => null } : undefined}
                styles={{
                    menu: (provided) => ({
                        ...provided,
                        ...(collapsible ? { position: "absolute", zIndex: 1000 } : {}),
                    }),
                    container: (base) => ({
                        ...base,
                        width: '100%',
                        flexGrow: 1,
                    }),
                    control: (base) => ({
                        ...base,
                        width: '100%',
                        ...style,
                    }),
                }}
                isOptionSelected={(option) => {
                    if (option.value === SELECT_ALL_OPTION.value) {
                        return multi && Array.isArray(selectedValue) && selectedValue.length === options.length;
                    }
                    return multi
                        ? Array.isArray(selectedValue) && selectedValue.some(sel => sel.value === option.value)
                        : selectedValue?.value === option.value;
                }}
                {...props}
            />
        </div>
    );
};


SmartDropdown.propTypes = {
    /**
     * An array of options in the format { value: any, label: string }.
     */
    options: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.any.isRequired,
            label: PropTypes.string.isRequired,
            isDisabled: PropTypes.bool,
        })
    ).isRequired,

    /**
     * The selected value(s). Should match the value type in options.
     * For multi-select, this should be an array of values.
     */
    value: PropTypes.oneOfType([
        PropTypes.any,
        PropTypes.arrayOf(PropTypes.any),
    ]),

    /**
     * If true, the user can select multiple values.
     */
    multi: PropTypes.bool,

    /**
     * Whether the dropdown is clearable (shows a clear button).
     */
    clearable: PropTypes.bool,

    /**
     * Whether the dropdown is searchable.
     */
    searchable: PropTypes.bool,

    /**
     * Placeholder text for the dropdown.
     */
    placeholder: PropTypes.string,

    /**
     * If true, the dropdown is disabled.
     */
    disabled: PropTypes.bool,

    /**
     * Custom styles for the dropdown components.
     */
    style: PropTypes.object,

    /**
     * Class name for the dropdown container.
     */
    className: PropTypes.string,

    /**
     * The ID of this component.
     */
    id: PropTypes.string,

    /**
     * Callback function called when the selected value(s) change.
     */
    onChange: PropTypes.func,

    /**
     * If true, the dropdown menu will not close after a selection is made.
     */
    closeMenuOnSelect: PropTypes.bool,
    collapsible: PropTypes.bool,
    openIcon: PropTypes.string,
    closeIcon: PropTypes.string,
    setProps: PropTypes.func,
};

SmartDropdown.defaultProps = {
    clearable: true,
    disabled: false,
    multi: false,
    searchable: true,
    placeholder: 'Select...',
    closeMenuOnSelect: true,
    collapsible: false,
    openIcon: '',
    closeIcon: '',
};

export default SmartDropdown;
